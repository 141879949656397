@import "../../styles/theme.scss";

.container {
  padding: 1rem;
  background-color: white;

  @include tablet-up {
    padding: 1.5rem;
  }
}

.heading {
  font-size: 1.25rem !important;
  margin: 0 0 1rem 0 !important;

  @include tablet-up {
    font-size: 1.75rem !important;
  }
}

.section {
  display: flex;
  margin: 0 0 1rem 0;

  &:last-child {
    margin: 0;
  }
}

.sectionHeading {
  font-size: 1.125rem !important;

  @include tablet-up {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}

.iconContainer {
  padding: 0 0.5rem 0 0;

  @include tablet-up {
    padding: 0.125rem 0.5rem 0 0;
  }
}

.link {
  color: #020678;
  font-size: 1.125rem;
  line-height: 1.75rem;
  max-width: 43rem;

  @include tablet-up {
    padding: 0.25rem 0.5rem 0 0;
    font-size: 1.25rem;
  }
}

.messageText {
  margin-bottom: 0.5rem !important;
}
